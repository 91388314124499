import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import headerContentImage from '../assets/images/abonos-bucaramanga-content-header-image.jpg';
import contentImage from '../assets/images/abonos-bucaramanga-content.jpg';
import contentImage2 from '../assets/images/abonos-bucaramanga-content2.jpg';
import leftContentImage from '../assets/images/abonos-bucaramanga-left-image.jpg';
import imageCedula from '../assets/images/bucaramanga-left-cedula-image.png';
import leftFooterContentImage from '../assets/images/bucaramanga-left-footer-image.jpeg';
import '../styles/PageAbonosBucaramanga.scss';
import { fontawesome } from '../utilities';

const PageAbonosBucaramanga = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='abonos-bucaramanga-2025-1-content' className="abonos-bucaramanga-2025-1">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerContentImage} alt="Abonos Bucaramanga header" title="Imagen header Abonos Bucaramanga" />
                </div>
            </section>
            <section id='header-image-section'>

            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Abonos Bucaramanga left content" title="Imagen izquierda Abonos Bucaramanga" />
                            <img className='img-fluid' src={leftFooterContentImage} alt="Abonos Bucaramanga left content" title="Imagen izquierda Abonos Bucaramanga" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=7cnhl2yuyx55jgob84opiw1stq64ra&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR</button></Link>
                                <Link to={'http://wa.link/jh91my'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> CONTÁCTANOS</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>
                            <h2 className='title-cedula'>abono o boleta en tu cédula</h2>
                            <img className='img-fluid' src={imageCedula} alt="Abonos Bucaramanga cédula" title="Imagen izquierda Abonos Bucaramanga cédula" />
                            <h5 className='description-cedula'>Si posees esta cédula no debes descargar tu boleta</h5>
                            <p className='content-cedula'>Si te registraste con pasaporte, tarjeta de identidad o posees la cédula nueva, por favor descarga tu boleta para presentarla en el evento.  <br /> <br />¡Esperamos verte pronto!</p>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>LIGA BETPLAY 2025-I</h2>
                            <h2 className='content-container__right-content__title'>ABONOS ATLETICO BUCARAMANGA</h2>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Abonos Bucaramanga content" title="Imagen contenido Abonos Bucaramanga" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage2} alt="Abonos Bucaramanga content" title="Imagen contenido Abonos Bucaramanga" />
                            <div className="content-text">
                                <h2>TÉRMINOS Y CONDICIONES DEL EVENTO</h2>
                                <p className='second-title'><strong>RECUERDE:</strong></p>
                                <p>Tener en cuenta las siguientes recomendaciones para hacer que su experiencia en este evento sea agradable. </p>
                                <ul>
                                    <li><strong>Política de Armas:</strong> Está prohibido el ingreso con armas.</li>
                                    <li><strong>Política de Alimentos y Bebidas:</strong> No se permite el ingreso de alimentos y bebidas externas.</li>
                                    <li><strong>Verificación de Identidad:</strong> Aceptamos únicamente la cédula amarilla para verificar la identidad. Si posees una cédula virtual o nueva, deberás descargar y presentar tu boleta para ingresar.</li>
                                    <li><strong>Política de Reembolso:</strong> Una vez que se ha adquirido la boleta y se ha finalizado la compra, no se realizarán reembolsos.</li>
                                    <li><strong>Presentación de Boletas:</strong> Tu boleta estará vinculada a tu número de cédula, el cual deberás presentar al ingresar al estadio.</li>
                                    <li><strong>Verificación del Documento:</strong> Antes de finalizar tu registro o compra, verifica que tu número de documento esté correctamente escrito. De esto depende que tu boleta quede correctamente vinculada a tu cédula.</li>
                                </ul>

                                <h2 className='final-message'>RESPONSABLE DEL EVENTO: <br />ATLETICO BUCARAMANGA<br />NIT :890.203.822-9</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default PageAbonosBucaramanga;