import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import abonosBucaramangaEventImage from '../assets/images/abonos-bucaramanga-content-header-image.jpg';
import { fontawesome } from '../utilities';

import { Link } from 'react-router-dom';
import partnerImage from '../assets/images/NUESTROS-CLIENTES.jpg';
import footerLogo from '../assets/images/logo-quick-footer.png';
import mediosDePago from '../assets/images/metodos-pago-footer.png';
import '../styles/Home.scss';
const Home = () => {
    const { regularIcons, brandIcons } = fontawesome;
    return (
        <div id="home-container">
            <header id='home-header-section'>
                <div className='header-container'>
                    {/* <img src={HeaderImage} alt='Quick Logo' title='Quickticket logo' /> */}
                    <div className='header-container__logo'></div>
                    <div className='header-container__buttons-container'>
                        <Link to={'https://ventas.quickticket.com.co/perfil.html'}>
                            <button className='btn btn-primary'>
                                <FontAwesomeIcon className='icon-address-card' icon={regularIcons['faAddressCard']} /> INICIAR SESIÓN
                            </button>
                        </Link>
                        <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}>
                            <button className='btn btn-secondary'>DESCARGAR BOLETAS</button>
                        </Link>
                    </div>
                </div>
            </header>
            <main id='home-content-section'>
                <section id='event-list-section'>
                    <div className='container-fluid event-list-container'>
                        <div className='row m-0 p-0'>
                            <div className='col-12 event-list-container__title-container'>
                                <h5 className='event-list-container__title'>EVENTOS DESTACADOS</h5>
                            </div>
                        </div>
                        <div className='container event-list-container__external'>
                            <div className='row'>
                                <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={abonosBucaramangaEventImage} alt='Imagen Abonos Atlético Bucaramanga' title='Imagen principal Abonos Atlético Bucaramanga' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/abonos-bucaramanga-2025-1'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>ABONOS ATLÉTICO BUCARAMANGA</h4>
                                        <p className='event-list-container__event-card__info__location'>LIGA BETPLAY 2025-I</p>
                                        <p className='event-list-container__event-card__info__city'>Bucaramanga</p>
                                    </div>
                                </div>
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={bucaramangaCaraboboEventImage} alt='Atlético Bucaramanga vs Carabobo fc' title='Imagen principal Atlético Bucaramanga vs Carabobo fc' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/bucaramanga-carabobo'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>AMISTOSO INTERNACIONAL</h4>
                                        <p className='event-list-container__event-card__info__title'>BUCARAMANGA VS CARABOBO FC</p>
                                        <p className='event-list-container__event-card__info__date'>13 de Julio del 2024</p>
                                        <p className='event-list-container__event-card__info__city'>ESTADIO AMERICO MONTANINI</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={fcfcImage} alt='Futbol de salón' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/futbol-salon'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>MT ORANGE F.S VS CLUB DEPORTIVO REAL NEIVA</h4>
                                        <p className='event-list-container__event-card__info__date'>20 de Julio del 2024</p>
                                        <p className='event-list-container__event-card__info__location'>Coliseo Cubierto Ipiales</p>
                                        <p className='event-list-container__event-card__info__location'>Ipiales</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={bucaramangaPartidoEventImage} alt='Atlético Bucaramanga vs Fortaleza' title='Imagen principal Atlético Bucaramanga vs Fortaleza' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/bucaramanga-partido'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>BUCARAMANGA VS FORTALEZA</h4>
                                        <p className='event-list-container__event-card__info__date'>09 de Noviembre del 2024</p>
                                        <p className='event-list-container__event-card__info__date'>8:20 PM.</p>
                                        <p className='event-list-container__event-card__info__city'>ESTADIO AMERICO MONTANINI</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={santanderPartidoEventImage} alt='Partido Real Santander vs Llaneros FC' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/santander-partido'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>REAL SANTANDER VS LLANEROS FC </h4>
                                        <p className='event-list-container__event-card__info__date'>16 de Octubre del 2024</p>
                                        <p className='event-list-container__event-card__info__date'>3:30 PM.</p>
                                        <p className='event-list-container__event-card__info__city'>ESTADIO VILLA CONCHA</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={andinaFestImage} alt='Imagen principal Andina Fest evento' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/andina-fest'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>ANDINA FEST </h4>
                                        <p className='event-list-container__event-card__info__date'>11 Y 12 de Octubre de 2024</p>
                                        <p className='event-list-container__event-card__info__location'>UNIDAD DEPORTIVA LA REGADERA</p>
                                        <p className='event-list-container__event-card__info__city'>GIGANTE, HUILA</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={carreraCorazonImage} alt='Imagen header carrera corazón' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/carrera-corazon'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>CARRERA DEL CORAZÓN</h4>
                                        <p className='event-list-container__event-card__info__date'>17 de Noviembre del 2024</p>
                                        <p className='event-list-container__event-card__info__location'>Manizales</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={backToTheMtImage} alt='Imagen header Back to the mini tk' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/back-to-the-mt'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>BACK TO THE MINI TK</h4>
                                        <p className='event-list-container__event-card__info__date'>25 de Octubre del 2024</p>
                                        <p className='event-list-container__event-card__info__location'>Expoferias</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={unlockTheGameImage} alt='Imagen header Cantina Decembrina' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/unlock-the-game'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>UNLOCK THE GAME</h4>
                                        <p className='event-list-container__event-card__info__date'>Del 6 al 8 Diciembre del 2024</p>
                                        <p className='event-list-container__event-card__info__city'>Medellin</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={chichaBrunchImage} alt='Imagen header Chicha Brunch' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/chicha-brunch'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>CHICHA BRUNCH</h4>
                                        <p className='event-list-container__event-card__info__date'>13 de Diciembre del 2024</p>
                                        <p className='event-list-container__event-card__info__location'>Centro de Eventos Opera</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={laJuergaEventImage} alt='Imagen header La Juerga' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/la-juerga'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>LA JUERGA</h4>
                                        <p className='event-list-container__event-card__info__date'>04 de Enero del 2025</p>
                                        <p className='event-list-container__event-card__info__date'>6:00 P.M.</p>
                                        <p className='event-list-container__event-card__info__location'>Ecoparque Los Yarumos</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={remateCabalgataEventImage} alt='Imagen header Remate de cabalgata con el señorazo' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/remate-cabalgata'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>REMATE DE CABALGATA CON EL SEÑORAZO (2025)</h4>
                                        <p className='event-list-container__event-card__info__date'>05 de Enero del 2025</p>
                                        <p className='event-list-container__event-card__info__location'>Carpa al Son que me toquen</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={remateCabalgataOleImage} alt='Imagen header Remate Cabalgata Olé' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/remate-cabalgata-ole'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>Remate de Cabalgata Jhon Alex </h4>
                                        <p className='event-list-container__event-card__info__date'>05 de Enero del 2025</p>
                                        <p className='event-list-container__event-card__info__location'>Carpa Olé</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={festivalVallenatoImage} alt='Imagen header Festival Vallenato' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/festival-vallenato'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>FESTIVAL VALLENATO</h4>
                                        <p className='event-list-container__event-card__info__date'>07 de Enero del 2025</p>
                                        <p className='event-list-container__event-card__info__location'>Carpa Olé</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={full80sEventImage} alt='Imagen header Full 80s' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/full-80-s'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>FULL 80s</h4>
                                        <p className='event-list-container__event-card__info__date'>08 de Enero del 2025</p>
                                        <p className='event-list-container__event-card__info__location'>Centro de Eventos Opera</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 event-list-container__event-card'>
                                    <div className='event-list-container__event-card__image-container'>
                                        <img src={despechonatoEventImage} alt='Imagen header Despechonato' />
                                        <div className='event-list-container__event-card__image-container__btn-container'>
                                            <Link to='/despechonato'>
                                                <button className='btn btn-primary'>MÁS INFORMACIÓN</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='event-list-container__event-card__info'>
                                        <h4 className='event-list-container__event-card__info__title'>DESPECHONATO FERIA DE MANIZALES 2025</h4>
                                        <p className='event-list-container__event-card__info__date'>09 de Enero del 2025</p>
                                        <p className='event-list-container__event-card__info__location'>Carpa al Son que me toquen</p>
                                        <p className='event-list-container__event-card__info__city'>Manizales</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section id='partner-list-section'>
                    <div className='container-fluid partner-list-container'>
                        <div className='row m-0 p-0'>
                            <div className='col-12 partner-list-container__title-container'>
                                <h5 className='partner-list-container__title'>CONOCE NUESTROS EVENTOS</h5>
                            </div>
                        </div>
                        <div className='container partner-list-container__external'>
                            <div className='row'>
                                <div className='col-12 partner-list-container__video-container' style={{ padding: '10px' }}>
                                    <video className='partner-list-container__video' autoPlay loop muted>
                                        <source src='video-quick-gift.mp4' type='video/mp4' />
                                    </video>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12' style={{ padding: '10px' }}>
                                    <img className='img-fluid' src={partnerImage} alt='Nuestros clientes' title='Nuestros clientes' />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <footer className='home-footer'>
                <div className='footer-container'>
                    <div className='container footer-container__external'>
                        <div className='row'>
                            <div className='col-3'>
                                <div className='footer-container__logo'>
                                    <img src={footerLogo} alt='Quick Logo' title='Quickticket logo' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-2 footer-container__payment-methods-container'>
                                <img src={mediosDePago} alt='Medios de pago' title='Medios de pago' />
                            </div>
                            <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start'>
                                <ul className='footer-container__link-list'>
                                    <li><Link to={'aliados-2'}>ALIADOS</Link></li>
                                    <li>VENDEMOS TU EVENTO</li>
                                    <li><Link to={'/politicas-de-privacidad'}>POLÍTICAS DE PRIVACIDAD</Link></li>
                                    <li><Link to={'/terminos-y-condiciones'}>TÉRMINOS Y CONDICIONES DE VENTA</Link></li>
                                </ul>
                            </div>
                            <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start'>
                                <ul className='footer-container__link-list'>
                                    <li>SERVICIOALCLIENTE@QUICKTICKET.COM.CO</li>
                                    <li>+57 3116746137</li>
                                </ul>
                            </div>
                            <div className='col-12 col-md-2 d-flex align-items-center'>
                                <div className='footer-container__social-media'>
                                    <a href={'https://www.facebook.com/QuickTicket'} target="_blank"><FontAwesomeIcon className='icon-facebook' icon={brandIcons['faFacebook']} /></a>
                                    <a href={'https://www.instagram.com/quickticket1/'} target="_blank"><FontAwesomeIcon className='icon-instagram' icon={brandIcons['faInstagram']} /></a>
                                    <a href={'https://api.whatsapp.com/send?phone=573116746137&text=Hola%20estoy%20interesado%20en%20adquirir%20entradas%20para%20el%20evento%20'} target="_blank"><FontAwesomeIcon className='icon-twitter' icon={brandIcons['faWhatsapp']} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Home;